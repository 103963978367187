$(function(){
    $('a[href^="#"]').click(function(){
      var adjust = 0;
      var speed = 400;
      var href= $(this).attr("href");
      var target = $(href == "#" || href == "" ? 'html' : href);
      var position = target.offset().top + adjust;
      $('body,html').animate({scrollTop:position}, speed, 'swing');
      return false;
    });
});

$(function(){
  $('.btn-gnavi').on('click',function(){
    var rightVal = 0;
    if($(this).hasClass('hb-open')){
      rightVal = -800;
      $(this).removeClass('hb-open');
    }else{
      $(this).addClass('hb-open');
    }

    $('#global-navi').stop().animate({
      right: rightVal
    }, 200);
  });
});

$(function(){
   // #で始まるアンカーをクリックした場合に処理
   $('a[href^="#"]').click(function() {
      // スクロールの速度
      var speed = 400; // ミリ秒
      // アンカーの値取得
      var href= $(this).attr("href");
      // 移動先を取得
      var target = $(href == "#" || href == "" ? 'html' : href);
      // 移動先を数値で取得
      var position = target.offset().top;
      // スムーススクロール
      $('body,html').animate({scrollTop:position}, speed, 'swing');
      return false;
   });
});

$(function(){
  setTimeout("$('.notice').fadeOut('slow')", 2000);
});

$(function(){
  if (window.matchMedia('(max-width: 800px)').matches) {
    const height = $("header.sp_header#fixed").height();
    $("main").css("margin-top", height);
    //スマホ処理
  } else if (window.matchMedia('(min-width:801px)').matches) {
    //PC処理
    const height = $("header#fixed").height();
    $("main").css("margin-top", height);
  }
});